var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form"},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 14 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"名称","label-col":{ span: 3 },"wrapper-col":{ span: 19 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'name',
              {
                initialValue: _vm.detail.name,
                rules: [{ required: true, message: '请输入！' }],
              },
            ]),expression:"[\n              'name',\n              {\n                initialValue: detail.name,\n                rules: [{ required: true, message: '请输入！' }],\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"措施","label-col":{ span: 3 },"wrapper-col":{ span: 19 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'method',
              {
                initialValue: _vm.detail.method,
                rules: [{ required: true, message: '请输入！' }],
              },
            ]),expression:"[\n              'method',\n              {\n                initialValue: detail.method,\n                rules: [{ required: true, message: '请输入！' }],\n              },\n            ]"}],attrs:{"auto-size":{ minRows: 3 }}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'type',
              {
                initialValue: _vm.detail.type,
                rules: [{ required: true, message: '请选择！' }],
              },
            ]),expression:"[\n              'type',\n              {\n                initialValue: detail.type,\n                rules: [{ required: true, message: '请选择！' }],\n              },\n            ]"}],staticStyle:{"width":"100%"}},_vm._l((_vm.typeList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"等级"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'level',
              {
                initialValue: _vm.detail.level,
                rules: [{ required: true, message: '请选择！' }],
              },
            ]),expression:"[\n              'level',\n              {\n                initialValue: detail.level,\n                rules: [{ required: true, message: '请选择！' }],\n              },\n            ]"}],staticStyle:{"width":"100%"}},_vm._l((_vm.levelList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"预警规则"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'standard',
              {
                initialValue: _vm.detail.standard,

                rules: [{ required: true, message: '请选择！' }],
              },
            ]),expression:"[\n              'standard',\n              {\n                initialValue: detail.standard,\n\n                rules: [{ required: true, message: '请选择！' }],\n              },\n            ]"}],staticStyle:{"width":"100%"}},_vm._l((_vm.form.getFieldValue('type') === 'rainFall'
                ? _vm.rainFallList
                : _vm.liquidLevelList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"预警值"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'value',
              {
                initialValue: _vm.detail.value,
                rules: [{ required: true, message: '请输入！' }],
              },
            ]),expression:"[\n              'value',\n              {\n                initialValue: detail.value,\n                rules: [{ required: true, message: '请输入！' }],\n              },\n            ]"}],staticStyle:{"width":"100%"}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"文件","label-col":{ span: 3 },"wrapper-col":{ span: 21 }}},[_c('a-button',{attrs:{"type":"primary"}},[_vm._v("上传")])],1)],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("关闭")]),_c('a-button',{attrs:{"htmlType":"submit","type":"primary"}},[_vm._v("提交")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }